import { acceptHMRUpdate, defineStore } from 'pinia'

const INFLUENCER_ONBOARDING_STEPS = [
  'name-country',
  'type',
  'bio',
  'website-links',
  'services',
  'similar-artists',
  'genres',
  'lang-lyrics-release',
  'picture',
]

type Step = (typeof INFLUENCER_ONBOARDING_STEPS)[number]

const initialState = () => ({
  activeStep: 0 as number,
  path: '/influencer/signup/v2' as string,
  steps: INFLUENCER_ONBOARDING_STEPS as Step[],
})

const state = initialState

export type InfluencerOnboardingFlowState = ReturnType<typeof state>

export const useInfluencerOnboardingFlowStore = defineStore(
  'influencerOnboardingFlow',
  {
    state: (): InfluencerOnboardingFlowState => ({ ...state() }),
    actions: {
      CHECK_ACTIVE_STEP(currentRoutePath: string) {
        this.steps.forEach((step, index) => {
          if (currentRoutePath.endsWith(`${this.path}/${step}/`))
            this.activeStep = index
        })
      },
      GO_TO_NEXT_STEP() {
        const path = `${this.path}/${this.steps[this.activeStep + 1]}`
        if (this.activeStep < this.steps.length) {
          this.activeStep++
        }

        const localePath = useLocalePath()
        return navigateTo(localePath({ path }))
      },
      GO_TO_PREVIOUS_STEP() {
        const path = `${this.path}/${this.steps[this.activeStep - 1]}`
        if (this.activeStep > 0) {
          this.activeStep--
        }

        const localePath = useLocalePath()
        return navigateTo(localePath({ path }))
      },
      INSERT_STEP_AFTER({ step, newStep }: { step: string; newStep: string }) {
        const stepIndex = this.steps.indexOf(step)

        if (stepIndex !== -1) {
          const stepsCopy = [...this.steps]
          stepsCopy.splice(stepIndex + 1, 0, newStep)

          this.steps = stepsCopy
        }
      },
      INSERT_STEP_BEFORE({ step, newStep }: { step: string; newStep: string }) {
        const stepIndex = this.steps.indexOf(step)

        if (stepIndex !== -1) {
          const stepsCopy = [...this.steps]
          stepsCopy.splice(stepIndex, 0, newStep)

          this.steps = stepsCopy
        }
      },
      REMOVE_STEP(step: string) {
        const stepIndex = this.steps.indexOf(step)

        if (stepIndex !== -1) {
          const stepsCopy = [...this.steps]
          stepsCopy.splice(stepIndex, 1)

          this.steps = stepsCopy
        }
      },
      SET_ACTIVE_STEP(activeStep: number) {
        this.activeStep = activeStep
      },
    },
    getters: {
      CURRENT_STEP(): string {
        return this.steps[this.activeStep]
      },
      COMPLETION_PERCENTAGE(): number {
        return (this.activeStep / (this.steps.length - 1)) * 100
      },
    },
  },
)

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useInfluencerOnboardingFlowStore, import.meta.hot),
  )
